import React from 'react';
import MainNav from '../Components/MainNav.js';
import FiltersSectionBig from '../Components/FiltersSectionBig.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import SelectList from 'components/select-list/index.js';
import Table from '../Components/Table.js';

export default function coachManageTeam() {
  const rows = [
    {
      id: 1,
      sfa_id: 'SFA-VJ-01',
      athlete_name: 'Eleanor Pena',
      email: 'random@xyz',
      status: {
        status: 'Verified',
        colors: 'bg-green-150 text-green-450',
      },
      delete: '',
    },
    {
      id: 2,
      sfa_id: 'SFA-VJ-02',
      athlete_name: 'Sunny Pena',
      email: 'random2@xyz',
      status: {
        status: 'Unverified',
        colors: 'bg-gray-150 text-gray-500',
      },
      delete: '',
    },
    {
      id: 3,
      sfa_id: 'SFA-VJ-03',
      athlete_name: 'Dhanush Chaubey',
      email: 'notArandom@xyz',
      status: {
        status: 'Rejected',
        colors: 'bg-red-150 text-red-350',
      },
      delete: '',
    },
  ];

  const columns = [
    {
      key: 'id',
      label: '',
      component: 'TextColumn',
    },
    {
      key: 'sfa_id',
      label: 'SFA ID',
      component: 'TextColumn',
    },
    {
      key: 'athlete_name',
      label: 'Athlete (Student) Name',
      component: 'TextColumn',
    },
    {
      key: 'email',
      label: 'Email',
      component: 'TextColumn',
    },
    {
      key: 'status',
      label: '',
      component: 'StatusColumn',
    },
    {
      key: 'delete',
      label: '',
      component: 'DeleteColumm',
    },
  ];

  const sportList = [
    'Cricket',
    'Football',
    'Tennis',
    'Basketball',
    'Volleyball',
    'Hockey',
    'Baseball',
    'Swimming',
    'Gymnastics',
    'Athletics',
    'Rugby',
    'Boxing',
    'Wrestling',
    'Diving',
    'Golf',
    'Cycling',
    'Rowing',
    'Running',
  ];

  return (
    <div>
      <MainNav />
      <div className="my-8 px-4 md:px-0 flex flex-col md:flex-row gap-4 justify-center">
        <div className="h-full border border-gray-200 rounded-lg">
          <FiltersSectionBig />
        </div>
        <div className="bg-white md:w-150 px-4 py-8 rounded-lg border border-gray-200">
          <div className="flex gap-1 md:gap-0 justify-between">
            <div>
              <div className="flex gap-2 items-center">
                <FontAwesomeIcon icon={faAngleLeft} className="text-gray-650"></FontAwesomeIcon>
                <p className="text-sm text-gray-650">Create Team</p>
              </div>
              <p className="md:text-xl text-lg font-bold text-gray-750 mt-2">Manage Team</p>
            </div>
            <div className="text-right">
              <p className="text-sm text-gray-650">Basket ball</p>
              <p className="md:text-xl text-lg font-bold text-gray-750 mt-2">
                Knockout - Male - U19
              </p>
            </div>
          </div>
          <div className="flex gap-y-4 flex-wrap mt-4 pt-4 shadow-paymentShadow justify-between">
            <div>
              <p className="text-sm text-gray-650">Team Name</p>
              <div className="flex items-center gap-2">
                <p className="font-bold text-gray-750">VangaBoys</p>
                <p className="py-0.5 px-1.5 bg-red-150 text-red-350 text-xs rounded">
                  Not Confirmed
                </p>
              </div>
            </div>
            <div className="flex">
              <div className="shadow-shadowRight px-3">
                <p className="text-sm text-gray-650 text-right">Minimum</p>
                <p className="font-bold text-gray-750 text-right">5 Players</p>
              </div>
              <div className="shadow-shadowRight px-3">
                <p className="text-sm text-gray-650 text-right">Maximum</p>
                <p className="font-bold text-gray-750 text-right">10 Players</p>
              </div>
              <div className="px-3">
                <p className="text-sm text-gray-650 text-right">Joined</p>
                <p className="font-bold text-gray-750 text-right">6 Players</p>
              </div>
            </div>
          </div>
          <div>
            <div className="shadow-paymentShadow pt-4 mt-4 mb-6">
              <p className="text-sm text-gray-650">
                Select team role and confirm team to make registration final.
              </p>
              <div className="flex gap-4 flex-wrap items-end mt-4">
                <div className="w-full">
                  <p className="text-sm text-gray-750">Captain</p>
                  <SelectList
                    selectedValue=""
                    setSelectedValue="setSelectedValue"
                    placeholder="Select Player"
                    listData={sportList}
                  />
                </div>
                <div className="w-full">
                  <p className="text-sm text-gray-750">Goal Keeper</p>
                  <SelectList
                    selectedValue=""
                    setSelectedValue="setSelectedValue"
                    placeholder="Select Player"
                    listData={sportList}
                  />
                </div>
                <div>
                  <button className="whitespace-nowrap px-4 h-customConfirmTeam border-2 text-gray-750 text-base font-bold rounded-lg hover:bg-gray-150 duration-300 transition-colors">
                    Confirm Team
                  </button>
                </div>
              </div>
            </div>

            <Table columns={columns} rows={rows} />
          </div>
        </div>
      </div>
    </div>
  );
}
